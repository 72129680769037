import { z } from 'zod';

const booleanSchema = z.union([z.literal('true'), z.literal('false')]);

export const publicEnvSchema = z.object({
  PUBLIC_ENVIRONMENT: z.union([z.literal('production'), z.literal('development'), z.literal('local')]),
  PUBLIC_API_URL: z.string().url(),
  PUBLIC_CDN_URL: z.string().url(),
  PUBLIC_ENABLE_CONSOLE_LOGS: booleanSchema.optional(),
  PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS: booleanSchema.optional(),
  PUBLIC_ENABLE_INTERCOM: booleanSchema.optional(),
  PUBLIC_ENABLE_SENTRY: booleanSchema.optional(),
  PUBLIC_CUSTOMERIO_SITE_ID: z.string().optional(),
  PUBLIC_MIXPANEL_TOKEN: z.string().optional(),
  PUBLIC_GOOGLE_DESTINATION_ID: z.string().optional(),
  PUBLIC_HOTJAR_ID: z.coerce.number().optional(),
  PUBLIC_ENABLE_SMART_SORTING: booleanSchema.optional(),
});

export type PublicEnv = z.infer<typeof publicEnvSchema>;
