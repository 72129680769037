import { invariant } from '../utils/invariant';

import { type PublicEnv, publicEnvSchema } from './publicEnvSchema';

const serverCache: PublicEnv | null = null;

/** See https://remix.run/docs/en/main/guides/envvars#browser-environment-variables */
export function getPublicEnv<T extends keyof PublicEnv>(key: T): PublicEnv[T] {
  if (typeof document === 'undefined') {
    const publicEnv = getServerPublicEnv();
    return publicEnv[key];
  }

  invariant(window.ENV, 'Missing the <PublicEnv /> component at the root of your app.');

  return window.ENV[key];
}

export function getServerPublicEnv(): PublicEnv {
  if (serverCache) return serverCache;
  const publicEnv = publicEnvSchema.parse(process.env);
  return publicEnv;
}
