/** Throw an error if the condition fails */
export function invariant(condition: unknown, message: string | (() => string)): asserts condition {
  if (condition) {
    return;
  }

  const provided: string = typeof message === 'function' ? message() : message;

  throw new Error(`Invariant failed: ${provided}`);
}
